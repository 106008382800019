/* Hide undo and redo buttons */
.c-bUEyht-jvfJsl-side-right > button:nth-child(1) {
  display: none;
}

.c-bUEyht-jvfJsl-side-right > button:nth-child(2) {
  display: none;
}

#TD-PrimaryTools-Image {
  display: none;
}
