#upload-image-comp:hover {
  #camera-upload-image {
    visibility: visible !important;
  }
  p {
    display: none !important;
  }
}

.chakra-modal__content {
  overflow-y: auto;
  overflow-x: hidden;
}

tr {
  td {
    height: 51px !important;
  }
}
